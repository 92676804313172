import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons/faAddressCard";
import { faCalendar } from "@fortawesome/free-regular-svg-icons/faCalendar";
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: "https://cal.com/bookbatchema",
    label: "Schedule a Meeting",
    icon: faCalendar,
  },
  {
    link: "mailto:batchema@mailmytaxes.com",
    label: "Email",
    icon: faEnvelope,
  },
  {
    link: "/vcard",
    label: "Digital Business Card",
    icon: faAddressCard,
  },
  {
    link: "https://www.linkedin.com/in/batchema",
    label: "LinkedIn",
    icon: faLinkedinIn,
  },
  {
    link: "https://github.com/batchema",
    label: "Github",
    icon: faGithub,
  },
];

export default data;
